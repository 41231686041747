import React from 'react';
import Router from 'next/router';
import styled, { css } from 'styled-components';

import { validators } from '@tager/web-core';
import { gtag, facebookPixel } from '@tager/web-analytics';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOrderComment,
  selectOrderSubmitDisabled,
  selectOrderSubmitLoading,
  setSubmitLoading,
} from '@/modules/Order/store/first-step';
import {
  selectOrderMode,
  selectOrderSettingsMinTotal,
  setErrorMessage,
  setOrderId,
  setStep,
} from '@/modules/Order/store/common';
import OrderFormSubmit from '@/modules/Order/components/OrderFormSubmit';
import { CreateOrderRequest, createOrder } from '@/services/requests/orders';
import {
  selectSelectedDate,
  selectSelectedTime,
} from '@/modules/Order/store/datetime';
import { dateToString } from '@/utils/common';
import { OrderFormMode } from '@/enums/order-form-mode';
import { selectPromoValue } from '@/modules/Order/store/promo';
import { PromoCodeType } from '@/enums/promo-code-type';
import {
  selectOrderEmail,
  selectOrderName,
} from '@/modules/Order/store/personal';
import {
  selectAddressCityId,
  selectAddressEntrance,
  selectAddressFlat,
  selectAddressFloor,
  selectAddressFrame,
  selectAddressHouse,
  selectAddressIntercomCode,
  selectAddressSelectedId,
  selectAddressStreet,
  selectAddressType,
} from '@/modules/Order/store/address';
import {
  selectFirstServiceValue,
  selectSecondServiceValue,
} from '@/modules/Order/store/services';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import { selectPriceWithoutDiscount } from '@/modules/Order/store';
import { media } from '@/utils/mixin';
import { scrollToElement } from '@/utils/scroll';
import { facebookPixelTrack } from '@/utils/analytics';

const OrderFormFirstStep = () => {
  const dispatch = useTypedDispatch();

  const mode = useTypedSelector(selectOrderMode);

  const priceWithoutDiscount = useTypedSelector(selectPriceWithoutDiscount);
  const minTotal = useTypedSelector(selectOrderSettingsMinTotal);

  const submitLoading = useTypedSelector(selectOrderSubmitLoading);
  const submitDisabled = useTypedSelector(selectOrderSubmitDisabled);
  const selectedDate = useTypedSelector(selectSelectedDate);
  const selectedTime = useTypedSelector(selectSelectedTime);
  const promo = useTypedSelector(selectPromoValue);

  const name = useTypedSelector(selectOrderName);
  const email = useTypedSelector(selectOrderEmail);
  const comment = useTypedSelector(selectOrderComment);

  const street = useTypedSelector(selectAddressStreet);
  const house = useTypedSelector(selectAddressHouse);
  const frame = useTypedSelector(selectAddressFrame);
  const entrance = useTypedSelector(selectAddressEntrance);
  const floor = useTypedSelector(selectAddressFloor);
  const flat = useTypedSelector(selectAddressFlat);
  const intercomCode = useTypedSelector(selectAddressIntercomCode);
  const firstServiceValue = useTypedSelector(selectFirstServiceValue);
  const secondServiceValue = useTypedSelector(selectSecondServiceValue);
  const cityId = useTypedSelector(selectAddressCityId);
  const houseType = useTypedSelector(selectAddressType);
  const addressCityId = useTypedSelector(selectAddressSelectedId);

  const getOrderRequest = (): CreateOrderRequest | null => {
    if (!selectedTime || !selectedDate) return null;

    return {
      date: dateToString(selectedDate),
      time: selectedTime,
      type: mode === OrderFormMode.Windows ? 'WINDOWS' : 'ROOMS',

      services: [
        firstServiceValue?.quantity || 0,
        secondServiceValue?.quantity || 0,
      ],
      promo: promo && promo.type === PromoCodeType.Promo ? promo.code : null,
      referral:
        promo && promo.type === PromoCodeType.Referral ? promo.code : null,

      addressId: addressCityId,
      addressCustom: addressCityId
        ? null
        : {
            city: cityId,
            type: houseType,
            street,
            house,
            frame,
            entrance: typeof entrance === 'undefined' ? null : entrance,
            apartment: typeof flat === 'undefined' ? null : flat,
            floor: typeof floor === 'undefined' ? null : floor,
            intercomCode,
          },
      name,
      email,
      comment,
    };
  };

  const validate = (): string | null => {
    if (!selectedDate) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-date');
      }
      return 'Выберите дату уборки';
    }

    if (!selectedTime) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-time');
      }
      return 'Выберите время уборки';
    }

    if (name === null || String(name).trim().length === 0) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-name');
      }
      return 'Введите ваше имя';
    }

    if (email === null || String(email).trim().length === 0) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-email');
      }
      return 'Введите ваш E-Mail';
    } else if (validators.email(email)) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-email');
      }
      return 'Неверный e-mail';
    }

    if (!addressCityId) {
      if (street === null || String(street).trim().length === 0) {
        if (window.innerWidth <= 767) {
          scrollToElement('order-form-street');
        }
        return 'Введите улицу';
      }

      if (house === null || String(house).trim().length === 0) {
        if (window.innerWidth <= 767) {
          scrollToElement('order-form-house');
        }
        return 'Введите номер дома';
      }
    }

    if (minTotal && priceWithoutDiscount < minTotal) {
      return 'Минимальная сумма заказа: ' + minTotal + ' руб.';
    }

    return null;
  };

  const onSubmit = async () => {
    const errorMessage = validate();
    if (errorMessage) {
      dispatch(setErrorMessage(errorMessage));
      return;
    }

    dispatch(setErrorMessage(null));
    dispatch(setSubmitLoading(true));

    try {
      const request = getOrderRequest();
      if (request) {
        const response = await createOrder(request);

        gtag.event('order:success');

        if (mode === OrderFormMode.Rooms) {
          dispatch(setOrderId(response.data.id));
          dispatch(setStep(2));
        } else {
          await Router.replace('/profile/orders');
        }
      } else {
        console.log('No Request');
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setSubmitLoading(false));
    }
  };

  return (
    <SubmitSection blocked={false} noLine={true}>
      <OrderFormSubmit
        isContainer={false}
        submitLabel="Далее"
        onSubmit={onSubmit}
        submitLoading={submitLoading}
        submitDisabled={submitDisabled}
        hasAgreement={true}
      />
    </SubmitSection>
  );
};

const SubmitSection = styled(OrderFormSection)`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    border: 0 none;
    padding: 0;
    margin: 0;
    display: block;
  `)}
`;

export default OrderFormFirstStep;
